var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dash-page-new',{attrs:{"title":_vm.$t('mailer.single.title'),"root":_vm.$t('Newsletter'),"no-content-condition":!_vm.loading && _vm.items.length === 0,"loading":_vm.loading},scopedSlots:_vm._u([{key:"header_action",fn:function(){return [_c('ws-button',{attrs:{"label":"Create","left-icon":"mdi-plus"},on:{"click":_vm.openNewCampaign}})]},proxy:true},{key:"default",fn:function(){return [_c('ws-data-table',{staticClass:"mt-5",attrs:{"items":_vm.items,"headers":_vm.headers,"row-action":_vm.openCampaign},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"wsDARKER shorten-text",staticStyle:{"min-width":"200px","font-size":"12px"}},[_vm._v(_vm._s(item.name || _vm.$t('Draft')))]),_c('h5',{staticClass:"wsDARKLIGHT mt-1",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.MONTH_DAY_TIME(item.date_modified,true,true)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('ws-chip',{attrs:{"color":_vm.NEWSLETTER_STATUS_COLOR(item.status),"no-wrap":"","text":_vm.getStatusText(item.status),"outlined":""}})]}},{key:"item.sent",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(_vm.calculateStatsPercent(item, 'sent'))+" ")])]}},{key:"item.opened",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(_vm.calculateStatsPercent(item, 'opened')))])]}},{key:"item.clicks",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(_vm.calculateStatsPercent(item, 'clicks')))])]}},{key:"item.unsubscribed",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(_vm.calculateStatsPercent(item, 'unsubscribed')))])]}},{key:"item.failed",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(_vm.calculateStatsPercent(item, 'failed'))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('ft-select',{attrs:{"items":_vm.actionsSelect},on:{"input":function($event){return _vm.editAction($event,item)}}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":_vm.wsACCENT}},[_vm._v("mdi-dots-horizontal")])],1)],1)]}}])})]},proxy:true},{key:"no-content",fn:function(){return [_c('div',{staticClass:"fill-height d-flex  justify-center"},[_c('div',{staticStyle:{"margin-top":"150px"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('ws-img',{attrs:{"height":"250","contain":"","src":"/library/img/finemailer/newsletter_logo.png"}})],1),_c('h2',{staticClass:"wsDARKER text-center"},[_vm._v(_vm._s(_vm.$t('mailer.single.no_content.title')))]),_c('h5',{staticClass:"font-weight-regular wsACCENT mt-2 text-center"},[_vm._v(_vm._s(_vm.$t('mailer.single.no_content.title')))]),_c('div',{staticClass:"d-flex justify-center"},[_c('ws-button',{staticClass:"mt-6",attrs:{"left-icon":"mdi-video","label":"mailer.single.help_button","outlined":"","color":_vm.wsACCENT},on:{"click":_vm.openHelpVideo}})],1)])])]},proxy:true},{key:"dialog",fn:function(){return [(_vm.displayDialog)?_c('newsletter-wizard',{attrs:{"newsletter":_vm.selectedNewsletter,"single":""},on:{"create":_vm.addNewsletter,"update":_vm.updateNewsletter},model:{value:(_vm.displayDialog),callback:function ($$v) {_vm.displayDialog=$$v},expression:"displayDialog"}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }